import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { rem } from "polished"

import Product from "./product/"

const Line = styled.div`
  height: ${rem(40)};
  background: ${(props) => props.theme.colorTerracotta};
`

const ProductsList = (props) => (
  <React.Fragment>
    <Line />

    {props.products.map((item, index) => (
      <Product key={index} isEven={index % 2 == 0} {...item} />
    ))}
  </React.Fragment>
)

ProductsList.propTypes = {
  products: PropTypes.array,
}

export default ProductsList
