import React from "react"

const CalculatorIcon1 = (props) => (
  <svg
    width="51"
    height="51"
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <line
      stroke="currentColor"
      x1="26.5833"
      y1="0.548218"
      x2="26.5833"
      y2="50.2612"
      strokeWidth="2.35757"
    />
    <line
      stroke="currentColor"
      x1="0.548828"
      y1="24.7513"
      x2="50.2618"
      y2="24.7513"
      strokeWidth="2.35757"
    />
  </svg>
)

export default CalculatorIcon1
