import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import { StoreContext } from "../store"

import Meta from "../components/meta"
import Hero from "../components/hero-with-content"
import Progress from "../components/page-products/progress"
import ProductsList from "../components/page-products/products-list"
import AdditionalProducts from "../components/page-products/additional-products"
import {
  progressProductTitleFromContentfulProduct,
  productNameFromContentfulProduct,
} from "../utils/product-title"
import { splitStringByBreakTag } from "../utils/array-helpers"
import { isPresent } from "../utils/value-helpers"
import { getMarkdownRemarkContent } from "../utils/markdown-remark-content"
import { getMetaKeywords } from "../utils/meta-keywords-from-array"

class ProductsPageContent extends React.Component {
  static contextType = StoreContext

  componentDidMount() {
    this.context.setHeaderTheme(`bright`)
  }

  render() {
    const {
      allContentfulProductsPipelinePage,
      allContentfulProgressBlockProductStage,
    } = this.props.data
    const {
      metaTitle,
      metaKeywords,
      metaDescription,
      heroTitle,
      heroContent,
      progressBlock,
      additionalProductsBlock,
      productDetailsBlock,
    } = allContentfulProductsPipelinePage.nodes?.[0] ?? {}
    const stages = (allContentfulProgressBlockProductStage?.nodes ?? []).map(
      (n, index) => ({ text: n.stageName, number: index })
    )

    const content = {
      meta: {
        title: metaTitle ?? ``,
        description: metaDescription ?? ``,
        keywords: getMetaKeywords(metaKeywords),
      },
      heroBlock: {
        title: heroTitle ?? ``,
        content: getMarkdownRemarkContent(heroContent),
        /* c8 ignore next 3 */
        bgColor: (props) => props.theme.colorSand,
        textColor: (props) => props.theme.colorBlack,
      },
      progressBlock: {
        title: progressBlock?.title ?? ``,
        products:
          progressBlock?.products?.map((p) => ({
            title: progressProductTitleFromContentfulProduct(p.product),
            indication: p.product?.productDisorder,
            partner: p?.productPartner?.partnerName,
            stage: stages.find((s) => s.text === p.productStage.stageName),
          })) ?? [],
      },
      productsSection:
        productDetailsBlock?.map((d) => ({
          title: productNameFromContentfulProduct(d.product),
          shortDescription: getMarkdownRemarkContent(d?.shortDescription),
          subtitle: d.product.productCode,
          image: d.productImage,
          button: isPresent(d.productLink)
            ? { url: d.productLink.productUrl, text: d.productLink.linkText }
            : undefined,
          blobs:
            d.productDetails?.map((ds) => ({
              title: isPresent(ds.detailPercentage)
                ? undefined
                : ds.detailTitle,
              content: getMarkdownRemarkContent(ds.detailContent),
              percentTitle: isPresent(ds.detailPercentage)
                ? ds.detailTitle
                : undefined,
              percentAmount: isPresent(ds.detailPercentage)
                ? ds.detailPercentage
                : undefined,
              tooltip: isPresent(ds.tooltipContent)
                ? {
                    content: getMarkdownRemarkContent(ds.tooltipContent),
                  }
                : undefined,
            })) ?? [],
        })) ?? [],
      additionalProducts: {
        title: additionalProductsBlock?.sectionTitle ?? ``,
        products:
          additionalProductsBlock?.products.map((p) => ({
            productName: p.productCode ?? p.productName,
            title: splitStringByBreakTag(p.productDisorder, -1),
          })) ?? [],
      },
    }

    return (
      <React.Fragment>
        <Meta {...content.meta} />
        <Hero {...content.heroBlock} />
        <Progress {...content.progressBlock} />
        <ProductsList products={content.productsSection} />
        <AdditionalProducts {...content.additionalProducts} />
        {` `}
      </React.Fragment>
    )
  }
}

ProductsPageContent.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.any,
}

const ProductsPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulProductsPipelinePage {
          distinct(field: slug)
          nodes {
            slug
            metaTitle
            metaKeywords
            metaDescription
            heroTitle
            heroContent {
              childMarkdownRemark {
                html
              }
            }
            additionalProductsBlock {
              products {
                productCode
                productDisorder
                productName
              }
              sectionTitle
            }
            progressBlock {
              title
              products {
                product {
                  productCode
                  productDisorder
                  productName
                }
                productStage {
                  stageName
                }
                productPartner {
                  partnerName
                  partnerLogo {
                    gatsbyImageData(width: 500, layout: CONSTRAINED)
                  }
                }
              }
            }
            productDetailsBlock {
              shortDescription {
                childMarkdownRemark {
                  html
                }
              }
              product {
                productCode
                productDisorder
                productName
              }
              productImage {
                gatsbyImageData(
                  layout: CONSTRAINED
                  width: 3000
                  quality: 60
                  placeholder: BLURRED
                  backgroundColor: "transparent"
                )
              }
              productLink {
                linkText
                productUrl
              }
              productDetails {
                ... on ContentfulProductDetailDetailsDetailWithTitle {
                  id
                  detailTitle
                  detailContent {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
                ... on ContentfulProductDetailDetailsDetailWithTooltip {
                  id
                  detailTitle
                  detailPercentage
                  detailContent {
                    childMarkdownRemark {
                      html
                    }
                  }
                  tooltipContent {
                    childMarkdownRemark {
                      html
                    }
                  }
                }
              }
            }
          }
        }
        allContentfulProgressBlockProductStage(
          filter: { isActive: { eq: true }, node_locale: { eq: "en-US" } }
          sort: { fields: orderNumber, order: ASC }
        ) {
          distinct(field: node_locale)
          nodes {
            stageName
          }
        }
      }
    `}
    render={(data) => <ProductsPageContent data={data} {...props} />}
  />
)

export default ProductsPage
