import React from "react"
import styled, { css } from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import { StaticQuery, graphql } from "gatsby"

import Container from "../container"
import Spacer from "../spacer"
import Row from "../grid/row"
import Col from "../grid/column"
import {
  progressBarWidthByStage,
  indicatorWidthByStage,
} from "../../utils/progress-bar"
import { isPresent } from "../../utils/value-helpers"

const Wrapper = styled.section``

const ProgressContainer = styled(Container)`
  width: ${rem(1480)};
`

const Heading = styled.h2.attrs({ className: `styled-h4` })`
  @media ${(props) => props.theme.smallDown} {
    font-size: ${rem(36)};
    line-height: 1.11;
    letter-spacing: 0.015em;
  }
`

const ProgressBarWrap = styled.div`
  @media ${(props) => props.theme.smallDown} {
    position: absolute;
    top: 0;
    left: ${rem(10)};
    right: ${rem(10)};
  }
`

const ProgressBar = styled.div`
  background: #dad6d2;
  position: relative;
  height: ${rem(20)};

  @media ${(props) => props.theme.smallDown} {
    height: ${rem(7)};
  }

  ${(props) =>
    props.progress &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: ${(props) => props.progress}%;
        height: 100%;
        background: ${(props) => props.theme.colorTangerine};
      }
    `}
   }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.progress + props.indicatorWidth}%;
    height: 100%;
    background: ${(props) => props.theme.colorTerracotta};
  }
`

const ProgressText = styled.div.attrs({ className: `styled-p` })`
  opacity: 0.5;

  @media ${(props) => props.theme.smallUp} {
    display: none;
  }
`

const ProductsRow = styled(Row)`
  @media ${(props) => props.theme.smallUp} {
    margin-top: ${rem(50)};
  }

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(15)};
    position: relative;

    &:not(:first-of-type) {
      margin-top: ${rem(20)};
    }
  }
`

const StepsCol = styled.div`
  width: calc(100% / ${(props) => props.numberOfStages});
  diplay: flex;
  justify-content: flex-start;

  @media ${(props) => props.theme.smallUp} {
    display: flex;
  }

  @media ${(props) => props.theme.smallDown} {
    display: none;
  }
`

const PartnerCol = styled(Col)`
  @media ${(props) => props.theme.smallDown} {
    display: none;
  }
`

const StyledHeadingCol = styled(Col)`
  display: flex;
  align-items: flex-end;
`

const HeadingRow = styled(Row)`
  align-items: flex-end;

  @media ${(props) => props.theme.smallDown} {
    margin-bottom: ${rem(10)};
  }

  @media ${(props) => props.theme.smallUp} and ${(props) =>
      props.theme.xlargeDown} {
    .styled-label {
      font-size: ${rem(9)};
    }
  }
`

const IndicationParagraph = styled.p.attrs({ className: `styled-p` })`
  opacity: 0.5;
`

const MobileStepCol = styled.div`
  @media ${(props) => props.theme.smallUp} {
    display: none;
  }
`

class Progress extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      scrolledIn: false,
    }
  }

  componentDidMount() {
    window?.addEventListener(`scroll`, this.checkScrollPosition)
  }

  componentWillUnmount() {
    window?.removeEventListener(`scroll`, this.checkScrollPosition)
  }

  checkScrollPosition = () => {
    if (!this.state.scrolledIn) {
      const scrollPosition =
        this.graphsContainer.current.getBoundingClientRect().top -
        window.innerHeight * 0.8

      if (scrollPosition < 0) {
        this.setState({
          scrolledIn: true,
        })
      }
    }
  }

  graphsContainer = React.createRef()

  render() {
    const { allContentfulProgressBlockProductStage } = this.props.data
    const stages = allContentfulProgressBlockProductStage?.nodes ?? []
    return (
      <Wrapper>
        <ProgressContainer>
          <Spacer top bottom>
            <Row>
              <Col smallUp={5}>
                <Heading>{this.props.title}</Heading>
              </Col>
            </Row>
          </Spacer>

          <div ref={this.graphsContainer}>
            <Spacer bottom>
              <HeadingRow>
                <Col smallDown={4} smallUp={1.1}>
                  <span className="styled-label">Product</span>
                </Col>

                <Col smallDown={4} smallUp={1.3}>
                  <span className="styled-label">Indication</span>
                </Col>

                <PartnerCol smallDown={0} smallUp={1.6}>
                  <span className="styled-label">Collaborator</span>
                </PartnerCol>

                <StyledHeadingCol smallDown={4} smallUp={8}>
                  {stages.map((stage, index) => (
                    <StepsCol
                      key={index}
                      className="styled-label stage-column"
                      numberOfStages={stages.length}
                      dangerouslySetInnerHTML={{
                        __html: isPresent(stage.stagePhase)
                          ? `${stage.stagePhase}<br />${stage.stageName}`
                          : stage.stageName,
                      }}
                    />
                  ))}

                  <MobileStepCol>
                    <span className="styled-label">Phase</span>
                  </MobileStepCol>
                </StyledHeadingCol>
              </HeadingRow>

              {this.props.products.map((item, index) => (
                <ProductsRow key={index}>
                  <Col smallDown={4} smallUp={1.1}>
                    <motion.div
                      initial={{ opacity: 0, transform: `translateX(-20px)` }}
                      animate={
                        this.state.scrolledIn && {
                          opacity: 1,
                          transform: `translateX(0px)`,
                        }
                      }
                      transition={{ duration: 0.75 + index * 0.1 }}
                    >
                      <p
                        className="styled-p"
                        dangerouslySetInnerHTML={{ __html: item.title }}
                      />
                    </motion.div>
                  </Col>

                  <Col smallDown={4} smallUp={1.3}>
                    <motion.div
                      initial={{ opacity: 0, transform: `translateX(-20px)` }}
                      animate={
                        this.state.scrolledIn && {
                          opacity: 1,
                          transform: `translateX(0px)`,
                        }
                      }
                      transition={{ duration: 0.75 + index * 0.2 }}
                    >
                      <IndicationParagraph
                        dangerouslySetInnerHTML={{ __html: item.indication }}
                      />
                    </motion.div>
                  </Col>

                  <PartnerCol smallDown={0} smallUp={1.6}>
                    <motion.div
                      initial={{ opacity: 0, transform: `translateX(-20px)` }}
                      animate={
                        this.state.scrolledIn && {
                          opacity: 1,
                          transform: `translateX(0px)`,
                        }
                      }
                      transition={{ duration: 0.75 + index * 0.2 }}
                    >
                      <IndicationParagraph
                        dangerouslySetInnerHTML={{ __html: item.partner }}
                      />
                    </motion.div>
                  </PartnerCol>

                  <Col smallDown={4} smallUp={8}>
                    <ProgressBarWrap>
                      <motion.div
                        initial={{ opacity: 0, transform: `scaleX(0)` }}
                        style={{ transformOrigin: `left` }}
                        animate={
                          this.state.scrolledIn && {
                            opacity: 1,
                            transform: `scaleX(1)`,
                          }
                        }
                        transition={{ duration: 0.75 + index * 0.3 }}
                      >
                        <ProgressBar
                          progress={progressBarWidthByStage(
                            stages.length,
                            item.stage.number
                          )}
                          indicatorWidth={indicatorWidthByStage(
                            stages.length,
                            item.stage.number
                          )}
                        />
                      </motion.div>
                    </ProgressBarWrap>

                    <motion.div
                      initial={{ opacity: 0, transform: `translateX(-20px)` }}
                      animate={
                        this.state.scrolledIn && {
                          opacity: 1,
                          transform: `translateX(0px)`,
                        }
                      }
                      transition={{ duration: 0.75 + index * 0.3 }}
                    >
                      <ProgressText>{item.stage.text}</ProgressText>
                    </motion.div>
                  </Col>
                </ProductsRow>
              ))}
            </Spacer>
          </div>
        </ProgressContainer>
      </Wrapper>
    )
  }
}

Progress.propTypes = {
  data: PropTypes.shape({
    allContentfulProgressBlockProductStage: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          stageName: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
  }).isRequired,
  stage: PropTypes.any,
  title: PropTypes.string,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      partner: PropTypes.string,
      indication: PropTypes.string,
      stage: PropTypes.shape({
        number: PropTypes.number,
        text: PropTypes.string,
      }),
    })
  ).isRequired,
}

const ProgressBlock = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulProgressBlockProductStage(
          filter: { isActive: { eq: true }, node_locale: { eq: "en-US" } }
          sort: { fields: orderNumber, order: ASC }
        ) {
          distinct(field: node_locale)
          nodes {
            stageName
            stagePhase
          }
        }
      }
    `}
    render={(data) => <Progress data={data} {...props} />}
  />
)

export default ProgressBlock
