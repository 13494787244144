import React, { useState } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { rem } from "polished"
import { motion, AnimatePresence } from "framer-motion"

import PlusIcon from "../../svg/calculator-icon-1"

import Button from "../../button"

import ContentBlock from "./content-block"

const ContentTopInnerContainer = styled.div`
  width: ${rem(600)};
  max-width: 90%;
  margin: 0 auto;

  @media ${(props) => props.theme.smallUp} {
    padding: ${rem(80)} 0;
  }

  @media ${(props) => props.theme.smallDown} {
    padding: ${rem(40)} 0;
  }
`

const Title = styled.h2.attrs({ className: `styled-h3` })``
const Subtitle = styled.span.attrs({ className: `styled-h3` })``

const ExpandButton = styled(motion.div)`
  display: inline-block;
  vertical-align: top;
  padding: ${rem(7)} ${rem(25)} ${rem(7)} ${rem(15)};
  width: ${rem(163)};
  text-align: left;
  border: 1px solid;
  font-size: ${rem(13)};
  letter-spacing: 0.175em;
  text-transform: uppercase;
  border-radius: 999em;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0;
  transition: background-color 0.3s, color 0.3s;

  svg {
    position: absolute;
    right: ${rem(10)};
    top: 50%;
    transform: translateY(-50%);
    width: ${rem(15)};
    transition: transform 0.3s;
  }

  ${(props) =>
    props.isOpen &&
    css`
      svg {
        transform: translateY(-50%) rotate(-45deg);
      }
    `}

  &:hover {
    background-color: ${(props) => props.theme.colorBlack};
    color: ${(props) => props.theme.colorWhite};

    svg {
      fill: ${(props) => props.theme.colorBlack};
    }
  }
`

const ShortDescription = styled.p.attrs({ className: `styled-h4` })`
  @media ${(props) => props.theme.smallUp} {
    padding: ${rem(40)} 0;
  }

  @media ${(props) => props.theme.smallDown} {
    padding: ${rem(20)} 0;
  }
`

const ExpandedContentOuterContainer = styled.div`
  position: relative;

  @media ${(props) => props.theme.smallUp} {
    padding-top: ${rem(80)};
  }

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(54)};
  }
`

const ExpandableContentInner = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
`

const ContentBlockCol = styled.div`
  @media ${(props) => props.theme.smallUp} {
    width: 50%;
    padding: 0 ${rem(20)};
    margin-left: auto;
  }

  @media ${(props) => props.theme.smallDown} {
    width: 100%;
  }
  ul {
    list-style-position: inside;
    text-indent: -0.5em;
    margin-left: 10px;
  }
`

const ButtonRow = styled.div`
  width: 100%;
`

const Content = (props) => {
  const [isOpen, openProduct] = useState(false)

  return (
    <ContentTopInnerContainer>
      <Title isOpen={isOpen} smallDown>
        {props.title}
      </Title>

      <Subtitle isOpen={isOpen} smallDown>
        {props.subtitle}
      </Subtitle>

      <ShortDescription
        dangerouslySetInnerHTML={{ __html: props.shortDescription }}
      />

      <ExpandedContentOuterContainer isOpen={isOpen}>
        {props.blobs.length > 0 && (
          <ExpandButton
            isOpen={isOpen}
            onClick={() => {
              openProduct(!isOpen)
            }}
          >
            {isOpen ? `close` : `learn more`}
            <PlusIcon />
          </ExpandButton>
        )}

        <AnimatePresence>
          {isOpen == true && (
            <ExpandableContentInner
              initial={{ opacity: 0, height: 0 }}
              animate={{ height: `auto`, opacity: 1 }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ ease: `easeOut`, duration: 0.75 }}
            >
              {props.blobs.map((item, index) => (
                <ContentBlockCol key={index}>
                  <ContentBlock isEven={props.isEven} {...item} />
                </ContentBlockCol>
              ))}

              {props.button && (
                <ButtonRow>
                  <Button target="_blank" to={props.button.url}>
                    {props.button.text}
                  </Button>
                </ButtonRow>
              )}
            </ExpandableContentInner>
          )}
        </AnimatePresence>
      </ExpandedContentOuterContainer>
    </ContentTopInnerContainer>
  )
}

Content.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  shortDescription: PropTypes.string,
  blobs: PropTypes.array.isRequired,
  button: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  isEven: PropTypes.bool,
}

export default Content
