import React, { useState } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { rem } from "polished"
import { motion, AnimatePresence } from "framer-motion"

const Container = styled.div`
  padding-top: ${rem(13)};
  padding-bottom: ${rem(50)};
  border-top: 1px solid ${(props) => props.theme.colorPaleGrey};
  position: relative;
`

const TextTitle = styled.h3`
  line-height: 1.25;
  letter-spacing: 0.175em;
  text-transform: uppercase;

  ${(props) =>
    props.ta &&
    css`
      text-align: left;
    `}
`

const TextContent = styled.div`
  font-size: ${rem(17)};
  line-height: 1.18;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colorPaleGrey};
  padding-top: ${rem(20)};
  ${(props) =>
    props.ta &&
    css`
      text-align: left;
    `}

  ul {
    li {
      &::before {
        content: "-";
      }
    }
  }
`

const PercentTitle = styled.h4`
  font-weight: 300;
  font-size: ${rem(33)};
  line-height: 1.21;
  letter-spacing: 0.015em;
`

const TooltipContainer = styled(motion.div)`
  position: absolute;
  z-index: 2;
  background: ${(props) => props.theme.colorTangerine};
  color: ${(props) => props.theme.colorWhite};
  top: 50%;
  transform: translateY(-50%);
  padding: ${rem(20)};
  padding-right: ${rem(50)};
  text-align: left;

  @media ${(props) => props.theme.largeUp} {
    width: ${rem(450)};
  }

  @media ${(props) => props.theme.smallUp} and ${(props) =>
      props.theme.largeDown} {
    width: ${rem(300)};
  }

  @media ${(props) => props.theme.smallDown} {
    display: none;
  }

  .styled-label {
    margin-bottom: ${rem(20)};
  }

  &::before {
    content: "";
    position: absolute;
    border-top: ${rem(20)} solid transparent;
    border-bottom: ${rem(20)} solid transparent;
    top: 50%;
    margin-top: ${rem(-20)};
  }

  ${(props) =>
    /* c8 ignore next 10 */
    props.isEven &&
    css`
      margin-left: ${rem(50)};
      left: 100%;

      &::before {
        border-right: ${rem(20)} solid ${(props) => props.theme.colorTangerine};
        right: 100%;
      }
    `}

  ${(props) =>
    props.isEven == false &&
    css`
      margin-right: ${rem(50)};
      right: 100%;

      &::before {
        border-left: ${rem(20)} solid ${(props) => props.theme.colorTangerine};
        left: 100%;
      }
    `}
`

const ContentBlock = (props) => {
  const [isHovered, setHover] = useState(false)

  return (
    <Container
      data-testid="Container"
      onMouseEnter={() => {
        setHover(true)
      }}
      onMouseLeave={() => {
        setHover(false)
      }}
    >
      {props.title && (
        <React.Fragment>
          <TextTitle ta={`left`}>{props.title}</TextTitle>
          <TextContent
            ta={`left`}
            dangerouslySetInnerHTML={{ __html: props.content }}
          />
        </React.Fragment>
      )}

      {props.percentAmount && (
        <React.Fragment>
          <TextTitle>{props.percentTitle}</TextTitle>
          <PercentTitle>{props.percentAmount}</PercentTitle>
          <TextContent dangerouslySetInnerHTML={{ __html: props.content }} />
        </React.Fragment>
      )}

      {props.tooltip && (
        <AnimatePresence>
          {isHovered && (
            <TooltipContainer
              isEven={props.isEven}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
            >
              <h5 className="styled-label">Reference</h5>
              <p
                className="styled-p"
                dangerouslySetInnerHTML={{ __html: props.tooltip.content }}
              />
            </TooltipContainer>
          )}
        </AnimatePresence>
      )}
    </Container>
  )
}

ContentBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  percentAmount: PropTypes.string,
  percentTitle: PropTypes.string,
  isEven: PropTypes.bool,
  tooltip: PropTypes.shape({
    content: PropTypes.string,
  }),
}

export default ContentBlock
