import React from "react"
import Container from "../container"
import Spacer from "../spacer"
import styled from "styled-components"
import { rem } from "polished"
import PropTypes from "prop-types"

import Row from "../grid/row"
import Col from "../grid/column"

const Section = styled.section`
  background: ${(props) => props.theme.colorSand};
`

const Heading = styled.h3.attrs({ className: `styled-h4` })`
  text-align: center;
`

const ProductEl = styled.div.attrs({ className: `styled-h4` })`
  text-align: center;

  span {
    opacity: 0.5;
    display: block;
  }
`
const AddtProductEl = styled.div.attrs({ className: `p` })`
  text-align: center;

  span {
    opacity: 0.5;
    display: block;
  }
`

const ProductCol = styled(Col)`
  padding-bottom: ${rem(50)};

  @media ${(props) => props.theme.smallUp} {
    &:not(:last-of-type) {
    }
  }
`

const Product = (props) => (
  <ProductEl>
    <span>{props.productName}</span>
    <div dangerouslySetInnerHTML={{ __html: props.title }} />
  </ProductEl>
)

const AdditionalProducts = (props) => (
  <Section id="CT131">
    <Container>
      <Spacer top bottom>
        <Heading>{props.title}</Heading>
      </Spacer>

      <Spacer bottom>
        <Row style={{ justifyContent: `center` }}>
          {props.products.map((item, index) => (
            <ProductCol smallUp={4} key={index}>
              <Product {...item} />
            </ProductCol>
          ))}
        </Row>
        <AddtProductEl>
          {` `}
          Products in development are investigational Digital Therapeutics not
          for sale. FDA has not yet evaluated the safety or effectiveness of
          these products.{` `}
        </AddtProductEl>
      </Spacer>
    </Container>
  </Section>
)

AdditionalProducts.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      productName: PropTypes.string,
    })
  ).isRequired,
  title: PropTypes.string,
}

Product.propTypes = {
  title: PropTypes.string,
  productName: PropTypes.string,
}

export default AdditionalProducts
