export function progressBarWidthByStage(numberOfStages, currentStage) {
  if (
    typeof numberOfStages !== `number` ||
    numberOfStages < 1 ||
    currentStage === 0
  ) {
    return 0
  }
  const step = 100 / numberOfStages

  return currentStage * step
}

export function indicatorWidthByStage(numberOfStages, currentStage) {
  if (typeof numberOfStages !== `number` || numberOfStages < 1) {
    return 0
  }

  const indicatorWidth = 100 / numberOfStages

  if (currentStage === 0 || currentStage === numberOfStages - 1) {
    return indicatorWidth
  }

  return indicatorWidth / 2
}
